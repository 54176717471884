var jqXhr;
module.exports = {
    init: function () {
        var isMobile = !($(window).width() >= 768);
        var url;

        // eslint-disable-next-line require-jsdoc
        function ajaxCall(options) {
            return $.ajax(options).fail();
        }

        $(document).ready(function () {
            // load live campaigns on ajax
            if ($('.live-section').length > 0) {
                url = $('.live-section').data('url');
                if (url !== null && typeof url !== 'undefined') {
                    jqXhr = ajaxCall({
                        url: url
                    });
                    jqXhr.done(function (data) {
                        // do something
                        $('.live-section').html(data);
                        $('body').trigger('live:initDial');
                        $('body').trigger('live:initTimeLimited');
                        $('body').trigger('livecampaign:loaded');
                    });
                }
            }

            // load soldout on ajax
            if ($('.soldout-section').length > 0) {
                url = $('.soldout-section').data('url');
                if (url !== null && typeof url !== 'undefined') {
                    jqXhr = ajaxCall({
                        url: url
                    });
                    jqXhr.done(function (data) {
                        // do something
                        var $html = $(data);

                        if (isMobile) {
                            if ($html.find('.campaign-box').length > 0) {
                                $('.newSoldOut-section').show();
                            }
                        } else {
                            // eslint-disable-next-line no-lonely-if
                            if ($html.find('.swiper-slide').length > 0) {
                                $('.soldout-campaign-area').show();
                            }
                        }
                        $('.soldout-section').html(data);
                        $('body').trigger('live:soldoutLoaded');
                        if (!isMobile) {
                            $(document).trigger('reInit');
                        }
                    });
                }
            }

            // // load soldout on ajax
            // if ($('.draw-section').length > 0) {
            //     url = $('.draw-section').data('url');
            //     if (url !== null && typeof url !== 'undefined') {
            //         jqXhr = ajaxCall({
            //             url: url
            //         });
            //         jqXhr.done(function (data) {
            //             // do something
            //             var $html = $(data);

            //             if (isMobile) {
            //                 $('.newSoldOut-section').show();
            //             } else {
            //                 $('.draw-campaign-area').show();
            //             }

            //             $('.draw-section').html(data);
            //             $('body').trigger('live:drawloaded');
            //             if (!isMobile) {
            //                 $(document).trigger('reInit');
            //             }
            //         });
            //     }
            // }

            // load winners campaigns
            if ($('.winners-body').length > 0) {
                url = $('.winners-body').data('url');
                if (url !== null && typeof url !== 'undefined') {
                    jqXhr = ajaxCall({
                        url: url
                    });
                    jqXhr.done(function (data) {
                        
                        // do something
                        $('.winners-body').html(data);
                        $('body').trigger('live:winnerLoaded');
                        if (!isMobile) {
                            $(document).trigger('reInit');
                        }
                        var $totalCountWinner = $(document).find('#totalCount').val();
                        // updating count on storefront
                        $('body').find('#home-show-more #winner-campaign-count').html($(document).find('#totalCount').val());
                        $totalCountWinner < 25 ? $('body').find('#home-show-more').hide() : $('body').find('#home-show-more').show();
                        $totalCountWinner < 1 ? $('#winnerScroller').hide() : '';
                    });
                }
            }
        });

        $(document).on('reloadLiveCampaigns', function (e, data) {
            var urlWithSort = data.url;
            if (urlWithSort !== null && typeof urlWithSort !== 'undefined') {
                jqXhr = ajaxCall({
                    url: urlWithSort
                });
                jqXhr.done(function (res) {
                    // do something
                    $('.live-section').html(res);
                    $('body').trigger('live:initDial');
                    $('body').trigger('live:initTimeLimited');
                });
            }
        });
    }
};
